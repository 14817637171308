* {
  margin: 0;
  padding: 0;
}
.navbar {
  display: flex;
  flex-direction: row;
  height: 93px;
  background-color:  white;
  align-items: center;
}

.pages {

  font-size: 17px;
  font-weight: 500;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: black;
  text-decoration: none;
  margin-left: 50px;
}

.logo {
  width: 82.64px;
  height: 86.17px;
}

.contact {
  margin-left: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 148px;
  height: 47px;
  left: 1108px;
  top: 23px;
  background: #d13f4d;
  border-radius: 8px;
}

.contactText {
  margin-left: 25px;
  position: static;
  width: 100px;
  height: 27px;
  left: 24px;
  top: 10px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
